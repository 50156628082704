
import { useStore } from "vuex";
import { ref, defineComponent, onMounted, watch, computed } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";

import { useRouter } from "vue-router";
import { Actions, Modules } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "@vuepic/vue-datepicker/dist/main.css";
import { formatDate } from "@/core/helpers/date";

export default defineComponent({
  name: "SubscriptionPortal",

  components: {},

  setup() {
    const store = useStore();
    const router = useRouter();

    let currentUser = computed(() => {
      return store.getters.currentUser;
    });

    let mySubscriptionPortalURL = computed(() => {
      return store.getters[Modules.PLAN + "getMySubscriptionPortalURL"];
    });

    onMounted(() => {
      setCurrentPageTitle("Subscription Portal");
      // router.push({ name: "kt-aside-primary" });
    });

    // Send request
    store.dispatch(Modules.PLAN + Actions.FETCH_MY_SUBSCRIPTION_PORTAL_URL);

    watch(mySubscriptionPortalURL, (value) => {
      if (value) {
        window.location.href = value;
      }
    });

    return {
      currentUser,
      mySubscriptionPortalURL,
      formatDate,
    };
  },
});
